.websiteHeader {
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 60px);
  max-width: 1200px;
  height: 75px;


  .opus {
    font-size: 30px;
    font-weight: 200;
    width: 200px;
  }

  .o {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    font-size: 27px;
    font-weight: 100;
    box-shadow: 0px 0px 5px 0px rgba(51, 51, 51, 0.24);
    border-radius: 7px;
  }
}

@media(max-width: 650px) {
  .websiteHeader {

    .loginPart {
      display: none;
    }
  }
}
