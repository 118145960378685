.imageCTA {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  width: calc(100vw - 60px);
  max-width: 1200px;
  height: 550px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;

  .titleCta {
    width: 700px;
    max-width: calc(100vw - 100px);

    font-weight: 500;
    color: #FFFFFF;
    font-size: 40px;
    text-align: center;
  }

  .widgetProject {
    width: 470px;
    max-width: calc(100vw - 100px);
  }
}
