.twoImages {

  display: flex;
  align-self: center;
  gap: 40px;

  .imagePoint {
    width: 450px;
    max-width: calc((100vw - 330px)/2);
    height: 550px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    padding: 50px;

    .textImagePoint {
      line-height: 24px;
    }
  }
}

@media(max-width: 850px) {

  .twoImages {
    flex-direction: column;

    .imagePoint {
      width: calc(100vw - 120px);
      max-width: calc(100vw - 120px);
      height: 480px;
      padding: 20px 30px;
    }
  }
}
