.timeLost {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 60px);
  max-width: 1200px;
  gap: 30px;

  .containerRow {
    flex-direction: row;
  }

  .itemTimeLost {
    position: relative;
    flex: 1;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;

    .complementaryText {
      color: #FFFFFF;
      font-size: 14px;
      margin-top: 25px;
      margin-left: 30px;
      width: 310px;
      line-height: 22px;
    }

    .sector {
      position: absolute;
      right: 0;
      margin-right: 20px;
      margin-top: 20px;
      padding: 5px 10px;
      border-radius: 99px;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.8);

      .plus {
        margin-right: 4px;
        width: 11px;
      }
    }

    .surTitle {
      margin-left: 30px;
      color: #FFFFFF;
      margin-bottom: 0;
      font-size: 16px;
    }

    .monthIndicator {
      margin: 0;
      margin-left: 30px;
      margin-bottom: 20px;
      font-size: 80px;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.80);
    }
  }
}

@media(max-width: 940px) {

  .timeLost {

    .containerRow {
        flex-direction: column;
    }

    .itemTimeLost {
      .fixableCompensator {
        display: none;
      }

      .complementaryText {
        margin-top: 70px;
      }

      .compensator {
        height: 160px;
      }

      .surTitle {
        font-size: 14Px;
      }

      .monthIndicator {
        font-size: 50px;

      }
    }
  }
}
