.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 60px);
  max-width: 1200px;
  padding: 0px 30px;

  .footer-top {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 60px;

    .footer-brand h1 {
      font-size: 24px;
      margin-bottom: 20px;
      font-weight: 300;
    }

    .footer-links {
      display: flex;
      justify-content: space-around;
      margin-bottom: 20px;
      gap: 100px;
      flex-wrap: wrap;

      .footer-section h3 {
        margin-bottom: 10px;
        font-weight: 600;
        color: #474747;
      }

      .footer-section a {
        display: block;
        margin-bottom: 5px;
        text-decoration: none;
        color: #CEC1BE;
      }
    }
  }
}


.allRightReserved {
  margin-top: 50px;
  display: flex;
  color: #b1a19e;
  font-size: 13px;
}


@media(max-width: 850px) {
  .footer {

    .footer-top {
      flex-direction: column;

      .footer-brand h1 {
        font-size: 45px;
        margin-bottom: 20px;
        font-weight: 200;
      }

      .footer-links {
        display: flex;
        justify-content: space-around;
        width: 100vw;
        gap: 0px;

        .footer-section {
          width: 180px;
        }
      }
    }
  }
}
