.landingPart {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(100vw - 60px);
  max-width: 1200px;
  height: 800px;

  .handIphone {
    margin-top: 100px;
    z-index: 3;
    display: flex;
    position: absolute;
    width: 600px;
    margin-left: 210px;
    animation: perspectiveEffect 2s ease-in-out;
  }

  .landingTextPart {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    z-index: 2;
    width: 100%;
    font-weight: 700;
    font-size: 80px;
    letter-spacing: -2px;
    line-height: 85px;
    animation: textEffect 2s ease-in-out;
  }

  .blueLightBackground {
    z-index: 1;
    margin-top: -100px;
    display: flex;
    position: absolute;
    width: 800px;
    transition: opacity ease-in-out 1s;
  }

  @keyframes perspectiveEffect {
    0% {
      transform: perspective(1000px) rotateX(15deg) rotateY(0deg) rotateZ(5deg) scale(0.8);
    }
    100% {
      transform: perspective(1000px) rotateX(0) rotateY(0) rotateZ(0) scale(1);
    }
  }

  @keyframes textEffect {
    0% {
      opacity: 0;
      margin-top: 400px;
      gap: 100px;
    }
    50% {
      opacity: 0;
      margin-top: 500px;
      gap: 100px;
    }
    100% {
      opacity: 1;
      margin-top: 100px;
      gap: 0px;
    }
  }
}


@media(max-width: 850px) {
  .landingPart {
    margin-bottom: 300px;

    .handIphone {
      margin-top: 500px;
    }

    .landingTextPart {
      font-size: 60px;
      line-height: 65px;
      margin-top: -430px;

    }

    .blueLightBackground {
      margin-top: 150px;
    }
  }

  @keyframes textEffect {
    0% {
      opacity: 0;
      margin-top: 400px;
      gap: 100px;
    }
    50% {
      opacity: 0;
      margin-top: 500px;
      gap: 100px;
    }
    100% {
      opacity: 1;
      margin-top: -400px;
      gap: 0px;
    }
  }
}
