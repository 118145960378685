.twoiPhones {
  margin-top: 50px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: calc(100vw - 160px);
  max-width: 1040px;
  overflow: hidden;
  height: 400px;
  background-color: #F7F7F7;
  padding: 50px;


  .textPart {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .titleTwoIphones {
      margin-top: 0px;
      font-size: 30px;
      font-weight: 500;
      width: 300px;
    }

    .subtitleTwoIphones {
      color: #B6B6B6;
      width: 365px;
      line-height: 24px;
      margin-bottom: 0;

    }
  }


  .twoIphonesImg {
    height: 1300px;
    object-fit: contain;
    margin-left: 80px;
  }
}

@media(max-width: 850px) {

  .twoiPhones {
    height: 700px;
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 30px;

    .textPart {

      .titleTwoIphones {
        margin-top: 0px;
        font-size: 30px;
        font-weight: 500;
        width: calc(100vw - 130px);
      }

      .subtitleTwoIphones {
        width: calc(100vw - 130px);

      }
    }

    .containeriPhones {
      height: 800px;
      margin-top: 60px;
      overflow: hidden;
      .twoIphonesImg {
        margin-top: -290px;
        height: 900px;
        margin-left: 200px;
      }
    }


  }
}
