.greyButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #F2F2F2;
  border-radius: 99px;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 14px;
  transition: transform ease-in-out 0.2s;
  white-space: nowrap;
  border: 0;
  color: #000000;

  .icon {
    height: 18px;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
}

.emptyIphones {
  margin-top: 100px;
  width: 800px;
  height: 1000px;


  .emptyIphonesMockup {
    position: absolute;
    width: 800px;
    opacity: 0.5;
  }

  .opusScreen {
    position: absolute;
    width: 300px;

  }
}

.modalQrCode {
  z-index: 10;
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 500px) / 2);
  margin-left: calc((100vw - 800px) / 2);
  width: 800px;
  height: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .titleModal, .titleModalPhone {
    font-weight: 600;
    font-size: 22px;
    margin: 45px 0px;
  }

  .titleModalPhone {
    display: none;
  }

  .containerQr {
    display: flex;
    gap: 40px;

    img {
        width: 300px;
        height: 300px;
    }
  }

  .widgetLink {
    display: none;
  }
}

@media (max-width: 850px) {
  .modalQrCode {
    margin-left: 20px;
    margin-top: calc((100vh - 400px) / 2);
    width: calc(100vw - 40px);
    height: 340px;

    .containerQr {
      display: none;
    }

    .titleModal {
      display: none;
    }

    .titleModalPhone {
      display: flex;
    }

    .widgetLink {
      display: flex;
      align-items: center;
      width: calc(100%  - 40px);
      height: 90px;
      margin-bottom: 20px;
      background-color: #F8F8F8;
      border-radius: 20px;
      color: #000000;

      .storeRond {
        width: 65px;
      }

      .titleStore {
        font-weight: 600;
        font-size: 17px;
        margin-left: 15px;
      }
    }
  }
}
