.timeline {
  width: calc(100vw - 60px);
  max-width: 1200px;  display: flex;
  justify-content: flex-end;

  .containerTimeline {
    position: relative;
    background-color: #F7F7F7;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 20px;

    .timelineImg {
      width: 800px;
    }

    .textPart {
      position: absolute;
      bottom: 0;
      .title {
        font-size: 30px;
        font-weight: 500;
        width: 360px;
      }

      .text {
        width: 310px;
        margin: 0;
        margin-bottom: 50px;
        font-size: 15px;
        line-height: 24px;

      }
    }
  }
}

@media(max-width: 850px) {
  .timeline {
    justify-content: center;

    .containerTimeline {
      padding-left: 30px;

      .containerTimelineImg {
        width: calc(100vw - 100px);
        overflow: hidden;
        align-items: flex-start;
        justify-content: flex-start;

        .timelineImg {
          margin-left: 0px;
          margin-bottom: -280px;
          width: 700px;
          object-fit: cover;
        }
      }


      .textPart {
        position: relative;

        .title {
          width: calc(100vw - 100px);
        }

        .text {
          width: calc(100vw - 100px);

        }
      }
    }
  }
}
