.twoPoints {

  display: flex;
  align-self: center;
  gap: 40px;

  .itemPoint {
    width: 450px;
    max-width: calc((100vw - 330px)/2);
    background-color: #F7F7F7;
    border-radius: 20px;
    padding: 50px;

    .textImagePoint {
      margin-top: 0;
      line-height: 24px;
    }

    .titleSection {
      font-size: 30px;
      font-weight: 500;
    }

    .appList {
      display: flex;
      gap: 20px;
      margin-top: 20px;

      .appIcon {
        width: 70px;
        object-fit: contain;

        &.notif {
            width: 85px;
        }
      }
    }
  }
}

@media(max-width: 850px) {

  .twoPoints {
    flex-direction: column;

    .itemPoint {
      width: calc(100vw - 120px);
      max-width: calc(100vw - 120px);
      padding: 20px 30px;
      padding-bottom: 35px;

      .appList {
        gap: 15px;

        .appIcon {
          width: 50px;

          &.opus {
            width: 85px;
          }

          &.opusNotif {
            width: 100px;
          }
        }
      }
    }
  }
}
