.landingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  .containerLargeTitle {
    display: flex;
    width: calc(100vw - 60px);
    max-width: 1050px;
    align-items: center;
    flex-direction: row-reverse;

    &.right {
      flex-direction: row;
    }

  }

  .complementaryText {
    width: 400px;
    color: #818181;
    margin-bottom: 40px;
    line-height: 25px;
    max-width: calc(100vw - 60px);
  }

  .largeTitle {
    width: calc(100vw - 60px);
    max-width: 1050px;
    justify-items: right;
    font-weight: 700;
    font-size: 80px;
    line-height: 97%;
    text-align: left;

    &.right {
      text-align: right;
    }
  }

  .largeTitleTest {
    width: calc(100vw - 60px);
    max-width: 1200px;
    justify-items: right;
    font-weight: 700;
    font-size: 80px;
    line-height: 97%;
    text-align: left;

    &.right {
      text-align: right;

    }
  }
}

@media(max-width: 850px) {

  .landingPage {

    .containerLargeTitle {
      flex-direction: column-reverse;

      &.right {
        flex-direction: column-reverse;
      }
    }

    .largeTitle {

      &.first {
        font-size: 65px;
      }

      &.second {
        font-size: 65px;
      }

      &.third {
        font-size: 65px;
      }

      &.fourth {
        font-size: 70px;
      }
    }
  }
}


@media(max-width: 550px) {

  .landingPage {

    .largeTitle {

      &.first {
        font-size: 55px;
      }

      &.second {
        font-size: 55px;
      }

      &.third {
        font-size: 55px;
      }

      &.fourth {
        font-size: 60px;
      }
    }
  }
}

@media(max-width: 450px) {

  .landingPage {

    .largeTitle {

      &.first {
        font-size: 48px;
      }

      &.second {
        font-size: 41px;
      }

      &.third {
        font-size: 45px;
      }

      &.fourth {
        font-size: 55px;
      }
    }
  }
}

@media(max-width: 400px) {

  .landingPage {

    .largeTitle {

      &.first {
        font-size: 40px;
      }

      &.second {
        font-size: 35px;
      }

      &.third {
        font-size: 40px;
      }

      &.fourth {
        font-size: 50px;
      }
    }
  }
}
