.introduction {
  background-color: #FAFAFA;
  border-radius: 20px;
  min-height: 300px;
  width: calc(100vw - 60px);
  max-width: 1200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .introductionText {
    width: 450px;
    margin-top: 50px;
    margin-left: 50px;
    line-height: 22px;
  }

  .introVisuel {
    width: 1200px;
    margin-bottom: -3px;
    align-self: center;
  }
}

@media(max-width: 850px) {

  .introduction {

    .introductionText {
      margin-top: 30px;
      margin-left: 30px;
      width: calc(100% - 60px);
      font-size: 18px;
    }

    .introVisuel {
      margin-top: 20px;
      width: 600px;
    }
  }
}
