

$color-primary: #007BFF;
$color-secondary: #55a7ff;
$color-tertiary: #9dcbff;
$color-light: #F8F9FA;
$color-dark: #343A40;
$color-gray: #6C757D;


$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;

// Transitions
$transition-base: all 0.3s ease-in-out;

body {
  margin: 0rem;
  font-family: $font-family-base;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;

  .hoverScale:hover {
    transform: scale(1.02);
  }

  .hoverScale {
    transition: transform ease-in-out 0.2s;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #000;
  }
}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
}

.classicFlex {
  display: flex;
  align-items: center;
}

.text {
  font-size: 17px;
  color: #A79B99;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.OverlayModal {
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(120, 120, 120, 0.7);
}
